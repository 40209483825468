import React, { useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';
import { useSwipeable } from 'react-swipeable';
import VideoPlayer from './VideoPlayer';
import * as mediasoupClient from 'mediasoup-client';

const socket = io('https://dr3d.net:3000', {
  transports: ['websocket', 'polling'],
  withCredentials: true,
});

const Feeds = ({ apiURL, userinfo }) => {
  const [liveStreams, setLiveStreams] = useState([]);
  const [currentStreamIndex, setCurrentStreamIndex] = useState(0); // Track current stream
  const [offsetY, setOffsetY] = useState(0); // Track swipe movement
  const [transitioning, setTransitioning] = useState(false); // Prevent further swipes during transition
  const videoRef = useRef(null); // Video reference
  const [hasInteracted, setHasInteracted] = useState(false);
 
  // Interaction handler to unlock autoplay with sound after the first video
  const handleInteraction = () => {
    setHasInteracted(true);
  };

  useEffect(() => {
    const fetchLiveStreams = async () => {
      try {
        const response = await fetch(`${apiURL}/api/getstreams`);
        const data = await response.json();
        const liveStreamsArray = Object.keys(data).map(key => ({ ...data[key] }));
        setLiveStreams(liveStreamsArray);
      } catch (error) {
        console.error('Error fetching live streams:', error);
      }
    };
    fetchLiveStreams();
  }, [apiURL]);

  const handleSwipeEnd = () => {
    // Transition to the next video if swipe is more than 25%
    if (Math.abs(offsetY) > window.innerHeight * 0.25) {
      if (offsetY < 0 && currentStreamIndex < liveStreams.length - 1) {
        setTransitioning(true);
        setCurrentStreamIndex(currentStreamIndex + 1); // Swipe up, go to next video
      } else if (offsetY > 0 && currentStreamIndex > 0) {
        setTransitioning(true);
        setCurrentStreamIndex(currentStreamIndex - 1); // Swipe down, go to previous video
      }
    }
    setOffsetY(0); // Reset swipe offset
  };

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (!transitioning) {
        setOffsetY(eventData.deltaY); // Track swipe direction and distance
      }
    },
    onSwiped: handleSwipeEnd,
    trackMouse: true, // Allow swipe detection via mouse for desktop
    preventDefaultTouchmoveEvent: true,
  });

  useEffect(() => {
    // When transitioning to a new stream, reset the state after a short delay
    if (transitioning) {
      setTimeout(() => {
        setTransitioning(false);
      }, 300); // Transition delay
    }
  }, [currentStreamIndex, transitioning]);

  return (
    <div className="block-container" {...handlers}>
      {liveStreams.length > 0 ? (
        <div
          className="swipe-container"
          style={{
            transform: `translateY(${offsetY}px)`, // Move the container during swipe
            transition: transitioning ? 'transform 0.3s ease' : 'none', // Smooth transition if moving to next/prev video
          }}
        >
          {/* Display the current VideoPlayer */}
          <VideoPlayer
            userinfo={userinfo}
            streamID={liveStreams[currentStreamIndex].streamID}
            hasInteracted={hasInteracted}
            onInteract={handleInteraction}
          />

          {/* Optionally preload the next VideoPlayer */}
          {currentStreamIndex < liveStreams.length - 1 && (
            <VideoPlayer
              userinfo={userinfo}
              streamID={liveStreams[currentStreamIndex + 1].streamID}
              hasInteracted={hasInteracted}
              onInteract={handleInteraction}
              style={{ display: 'none' }} // This one won't show until swiped
            />
          )}
        </div>
      ) : (
        <div className="no-streams-container">
        {/* Video when no live streams are available */}
        <video
          src="/streamerz.mp4" // Path to your video in the public folder
          autoPlay
          muted
          loop
          playsInline
          className="background-video"
        />
        {/* Animated text overlay */}
        <div className="overlay-text">
          <h1>STREAMERZ</h1><br></br><br></br>
          <h2>Waiting for the next live stream...</h2>
        </div>
      </div>
      )}
    </div>
  );
};

export default Feeds;
