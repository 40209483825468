import React, { createContext, useContext, useState } from 'react';
import { Device } from 'mediasoup-client';

const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [device, setDevice] = useState(null);

  const createDevice = async (rtpCapabilities) => {
    if (!device) {
      const newDevice = new Device();
      await newDevice.load({ routerRtpCapabilities: rtpCapabilities });
      setDevice(newDevice);
    }
    return device;
  };

  return (
    <DeviceContext.Provider value={{ device, createDevice }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  return useContext(DeviceContext);
};
