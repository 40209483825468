// src/components/GoLive.js
import React, { useState, useEffect } from 'react';
import './FriendsPage.css'; // Add custom CSS here

const Friends = ({ uniqueId,apiURL }) => {
  const [followedFriends, setFollowedFriends] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  useEffect(() => {
    // Fetch followed friends on mount
    fetchFollowedFriends();
  }, []);

  const fetchFollowedFriends = async () => {
    try {
      const response = await fetch(`/api/friends/${uniqueId}`);
      const data = await response.json();
      setFollowedFriends(data);
    } catch (error) {
      console.error('Error fetching followed friends:', error);
    }
  };

  const handleSearchFollowed = () => {
    const filteredFriends = followedFriends.filter(friend =>
      friend.Username.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(filteredFriends);
  };

  const handleSearchPeople = async () => {
    try {
      const response = await fetch(`/api/search-friends?query=${searchInput}`);
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching for friends:', error);
    }
  };
  const scrollLeft = () => {
    document.querySelector('.friends-list').scrollBy({ left: -200, behavior: 'smooth' });
  };
  
  const scrollRight = () => {
    document.querySelector('.friends-list').scrollBy({ left: 200, behavior: 'smooth' });
  };
  
  return (
    <div className="friends-page">
      <h2>Friends</h2>
      
      {/* Search followed friends */}
      <div className="search-box">
        <input 
          type="text" 
          placeholder="Search followed friends"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearchFollowed}>Search</button>
      </div>

      {/* Horizontal scrolling list for followed friends */}
      <div className="friends-list-container">
        <button className="scroll-left"  onClick={scrollLeft}>{"<"}</button>
        <div className="friends-list">
          {searchResults.length > 0 ? searchResults.map(friend => (
            <div key={friend.id} className="friend-item">
              <img 
                src={friend.profileImage} 
                alt={friend.Username} 
                className="friend-profile-image" 
              />
              <p>@{friend.Username}</p>
            </div>
          )) : followedFriends.map(friend => (
            <div key={friend.id} className="friend-item">
              <img 
                src={friend.profileImage} 
                alt={friend.Username} 
                className="friend-profile-image" 
              />
              <p>@{friend.Username}</p>
            </div>
          ))}
        </div>
        <button className="scroll-right"  onClick={scrollRight}>{">"}</button>
      </div>

      {/* Search for new friends */}
      <div className="search-box">
        <input 
          type="text" 
          placeholder="Search for friends (email or tag name)" 
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <button onClick={handleSearchPeople}>Search</button>
      </div>

      {/* Horizontal scrolling list for new friends */}
      <div className="friends-list-container">
        <button className="scroll-left"  onClick={scrollLeft}>{"<"}</button>
        <div className="friends-list">
          {searchResults.map(friend => (
            <div key={friend.id} className="friend-item">
              <img 
                src={friend.profileImage} 
                alt={friend.Username} 
                className="friend-profile-image" 
              />
              <p>@{friend.Username}</p>
            </div>
          ))}
        </div>
        <button className="scroll-right"  onClick={scrollRight}>{">"}</button>
      </div>
    </div>
  );
};

export default Friends;