// src/components/GoLive.js
import React, { useState } from 'react';

const Search = ({ uniqueId,apiURL }) => {

  return (
    <div className="go-live-container">
      <h2>Search</h2>
   
      <style jsx>{`
        .go-live-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100vh; /* Center vertically */
          color:#fff;
          background-color: #000; /* Optional background color */
          height: calc(92vh - 60px); /* Full height minus bottom nav height */
         
        }
        .start-live-button {
          background-color: red; /* Red button */
          color: white;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 18px;
          margin-top: 20px; /* Space above the button */
        }
      `}</style>
    </div>
  );
};

export default Search;
