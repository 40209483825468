// src/components/GoLive.js
import React, { useState } from 'react';

const Account = ({ uniqueId,apiURL }) => {

  return (
    <div className="account-container">
      <h2>Account</h2>
   
    </div>
  );
};

export default Account;
