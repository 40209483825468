// src/components/BottomNav.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserFriends, faVideo, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'; // Import the required icons

const BottomNav = ({ isLoggedIn, onLoginClick, selectedPage, onPageChange ,apiURL}) => {
    return (
      <div className="bottom-nav">
        {isLoggedIn ? (
          <>
            <button 
              className={`menu-button ${selectedPage === 'FEEDS' ? 'active' : ''}`} 
              onClick={() => onPageChange('FEEDS')}
            >
              <FontAwesomeIcon icon={faHome} />
              <span>FEEDS</span>
            </button>
            <button 
              className={`menu-button ${selectedPage === 'FRIENDS' ? 'active' : ''}`} 
              onClick={() => onPageChange('FRIENDS')}
            >
              <FontAwesomeIcon icon={faUserFriends} />
              <span>FRIENDS</span>
            </button>
            <button 
              className={`menu-button ${selectedPage === 'GOLIVE' ? 'active' : ''}`} 
              onClick={() => onPageChange('GOLIVE')}
            >
              <FontAwesomeIcon icon={faVideo} />
              <span>GOLIVE!</span>
            </button>
            <button 
              className={`menu-button ${selectedPage === 'MESSAGES' ? 'active' : ''}`} 
              onClick={() => onPageChange('MESSAGES')}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <span>MESSAGES</span>
            </button>
            <button 
              className={`menu-button ${selectedPage === 'ACCOUNT' ? 'active' : ''}`} 
              onClick={() => onPageChange('ACCOUNT')}
            >
              <FontAwesomeIcon icon={faUser} />
              <span>ACCOUNT</span>
            </button>
          </>
        ) : (
          <button className="login-button" onClick={onLoginClick}>Login</button>
        )}
      </div>
    );
  };
  

export default BottomNav;
