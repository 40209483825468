// src/components/App.js
import React, { useState,useEffect } from 'react';
import Header from './Header';
import BottomNav from './BottomNav';
import VideoPlayer from './VideoPlayer';
import Login from './Login';
import Feed from './Feed';
import Feeds from './Feeds'; // Import Feeds component
import GoLive from './GoLive'; // Import Feeds component
import Friends from './Friends'; // Import Feeds component
import Messages from './Messages'; // Import Feeds component
import Account from './Account'; // Import Feeds component
import Search from './Search'; // Import Feeds component
import { Helmet } from 'react-helmet'; // Import Helmet


import { DeviceProvider } from './DeviceContext';

const App = () => {
  const [activeFeed, setActiveFeed] = useState('Random');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({}); 
  const [showLogin, setShowLogin] = useState(false); 
  const [selectedPage, setSelectedPage] = useState('FEEDS'); // State for selected page
  const [isStreaming, setIsStreaming] = useState(false);

  useEffect(() => {
    setViewportHeight();
  },[]);
  function setViewportHeight() {
    const viewportHeight = window.visualViewport.height; // Get height excluding the toolbars
    const viewportWidth = window.visualViewport.width; // Get height excluding the toolbars

    console.log("Current viewport size is: " + viewportHeight + ", "+ viewportWidth);
    document.documentElement.style.setProperty('--app-height', `${viewportHeight}px`);
    document.documentElement.style.setProperty('--app-width', `${viewportWidth}px`);
  }
  
  // Set the viewport height on page load and when resizing the screen
  window.addEventListener('resize', setViewportHeight);
  window.addEventListener('load', setViewportHeight);
  let apiURL = "https://dr3d.net:3000";
  const handleLoginSuccess = (data) => {
    setIsLoggedIn(true);
    setUserInfo(data); // Save user information from the response
    console.log(JSON.stringify("userdata:"+data))
    setShowLogin(false); // Close login overlay
  };
  const handlePageChange = (page) => {
    setSelectedPage(page); // Update the selected page state
  };
  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  return (

    <DeviceProvider>
    <div className="app-container">
    {!isStreaming && ( <Header  apiURL={apiURL} activeFeed={activeFeed} setActiveFeed={setActiveFeed} />)};
     <div className="content-area"> {/* Fixed size content area */}
        {selectedPage === 'FEEDS' && <Feeds userinfo={userInfo} apiURL={apiURL} myId={isLoggedIn ? userInfo.uniqueId : generateRandomString(64)  } />}
        {selectedPage === 'FRIENDS' && <Friends apiURL={apiURL} />}
        {selectedPage === 'MESSAGES' && <Messages apiURL={apiURL} />}
        {selectedPage === 'ACCOUNT' && <Account apiURL={apiURL}  />}
        {selectedPage === 'SEARCH' && <Search apiURL={apiURL} />}
        {selectedPage === 'GOLIVE' && <GoLive setIsStreaming={setIsStreaming} isStreaming={isStreaming} userinfo={userInfo} uniqueId={userInfo.uniqueId} apiURL={apiURL} />} {/* Pass uniqueId for GoLive */}
      </div>
      {!isStreaming && (<BottomNav  apiURL={apiURL}
        isLoggedIn={isLoggedIn} 
        onLoginClick={() => setShowLogin(true)} 
        selectedPage={selectedPage} // Pass selected page to BottomNav
        onPageChange={handlePageChange} // Pass function to change page
      />)};
       {showLogin && <Login apiURL={apiURL} onClose={() => setShowLogin(false)} onLoginSuccess={handleLoginSuccess} />}
    </div>
    </DeviceProvider>
  );
};

export default App;
