// src/components/Header.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'; // Import the magnifying glass icon

const Header = (apiURL) => {
  const [activeFeed, setActiveFeed] = useState('Random');

  const feeds = ['Random', 'Friends', 'Local'];

  return (
    <div className="header">
      <div className="feeds-container"> {/* Container for feed buttons */}
        {feeds.map((feed, index) => (
          <React.Fragment key={feed}>
            <button
              className={`feed-button ${activeFeed === feed ? 'active' : ''}`}
              onClick={() => setActiveFeed(feed)}
            >
              {feed}
            </button>
            {index < feeds.length - 1 && <span className="separator">|</span>} {/* Add separator */}
          </React.Fragment>
        ))}
      </div>
      <button className="search-button" onClick={() => console.log('Navigate to Search Page')}>
        <FontAwesomeIcon icon={faSearch} />
      </button>
     
    </div>
  );
};

export default Header;
