import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css'; // Import your main CSS file for global styles
import './index.css'; // Import your main CSS file for global styles
import App from './components/App'; // Import your main App component

import { Helmet } from 'react-helmet'; // Import Helmet
// Create a root for rendering
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component
root.render(
  <React.StrictMode>
    
    <App>    
      <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
          <meta name="apple-mobile-web-app-status-bar-style" content="black"></meta>
          <meta name="apple-mobile-web-app-capable" content="yes"/> 
      </Helmet>
    </App>
  </React.StrictMode>
);
